body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
 font-family: "OswaldBold";
 src: local("OswaldBold"),
  url(/static/media/Oswald-Bold.452bfeb5.ttf) format("truetype");
}

@font-face {
 font-family: "OswaldExtraLight";
 src: local("OswaldExtraLight"),
  url(/static/media/Oswald-ExtraLight.bae2f010.ttf) format("truetype");
}

@font-face {
 font-family: "OswaldLight";
 src: local("OswaldLight"),
  url(/static/media/Oswald-Light.6ee38c23.ttf) format("truetype");
}

@font-face {
 font-family: "OswaldMedium";
 src: local("OswaldMedium"),
  url(/static/media/Oswald-Medium.14cf874b.ttf) format("truetype");
}

@font-face {
 font-family: "OswaldRegular";
 src: local("OswaldRegular"),
  url(/static/media/Oswald-Regular.a7ccbd3c.ttf) format("truetype");
}

@font-face {
 font-family: "OswaldSemiBold";
 src: local("OswaldSemiBold"),
  url(/static/media/Oswald-SemiBold.6a5d13d3.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 900px) {
 .bodyCopyImage{
     width:100%!important;
    }
}

@media only screen and (max-width: 600px) {
  .removeImagePadding {
    display:none;
  }

  .bodyCopyImage{
     display:none;
  }
  .googleMap iframe {
      height: 250px!important;
  }

  .contactCopy{ 
    text-align:center!important;
  }
  .paddingRight{
    padding-right:0!important;
    padding-left: 0!important;
  }
  .paddingLeft {
    padding-left:0!important;
    padding-right:0!important;
  }
}

@media only screen and (max-width: 480px) {
  .logo img {
      width: 100px;
      height: auto;
    }
  .mobileCarousel {
    display:block!important;
  }
  .desktopCarousel {
    display:none!important;
  }
  .noMobile {
    display: none!important;
  }
}

.desktopCarousel {
  display:flex;
}

.mobileCarousel {
  display:none;
}

.banner {
 position: fixed;
 top:0;
right: 0;
left: 0;
z-index: 999;
}

.servicesTitle {
  font-family: "OswaldBold";
  color: #ffffff;
  background-color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 0 13px 4px 13px;
}

.bgBlack {
  background-color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 0 13px 4px 13px;
}

.googleMap iframe {
      width: 100%;
      height: 450px;
    }

.video-section {
  margin-top: 71px;
}

.logo {
  /* padding: 0 0 0 6px; */
}

.logo img {
  width: 185px;
  height: auto;
}

.bodyCopy{
  font-family: "OswaldRegular";
  font-size: 21px;
  text-align: center;
}

.contactCopy{
  font-family: "OswaldRegular";
  font-size: 21px;
}

.contactButton {
    color: #fff;
    background-color: #ae001d;
    border-color: #ae001d;
    border: none;
    padding: 6px 10px 9px 10px;
    font-family: "OswaldMedium";
    font-size: 21px;

}

.bodyCopyContact{
  font-family: "OswaldRegular";
  font-size: 21px;
  text-align: center;
  vertical-align: middle;

}

.nav-link{
  font-family: "OswaldMedium";
  color: #ffffff;
  font-size: 18px;
}

.nav-link:hover { 
  color: #777777; 
}

.nav-link.active {
  color: #ffffff;
}

.nav-link.active:hover {
  color: #777777;
}

.boldText{
  font-family: "OswaldBold";
}

.card-img-overlay {
  font-family: "OswaldBold";
  padding: 0;
   background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.3); /* Black see-through */
}

.card {
  border: none;
  border-radius: 0;
  padding: 0;
  --bs-card-border-radius: 0;
  --bs-card-inner-border-radius: calc(0);
  background: none;
}

.card-title  {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-content: flex-end;
  flex-direction: column;
  font-size: 29px;
}

.black-row {
  background-color: black;
}

.alignText {
   display: flex;
  align-items: center;
}

.footer {
  background-color: #ae001d;
  padding: 10px 0;
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

.navbar-toggler {
  border:none;
}

.footer{
  font-family: "OswaldMedium";
  font-size: 16px;
}

.carousel-caption p {
  font-family: "OswaldBold";
  font-size: 22px;
}

.form-control {
  border: 2px solid #ae001d;
  border-radius: 0px!important;
  font-family: "OswaldRegular";
}

.form-label {
  font-family: "OswaldRegular";
    font-size: 21px;

}

.formSubmit {
  font-family: "OswaldMedium";
  font-size: 24px;
  color: #ffffff;
  background-color: #ae001d;
  border: none;
  border-radius: 0px;
  padding: 4px 10px 7px 10px;

}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


