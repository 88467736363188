body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
 font-family: "OswaldBold";
 src: local("OswaldBold"),
  url("./assets/Oswald-Bold.ttf") format("truetype");
}

@font-face {
 font-family: "OswaldExtraLight";
 src: local("OswaldExtraLight"),
  url("./assets/Oswald-ExtraLight.ttf") format("truetype");
}

@font-face {
 font-family: "OswaldLight";
 src: local("OswaldLight"),
  url("./assets/Oswald-Light.ttf") format("truetype");
}

@font-face {
 font-family: "OswaldMedium";
 src: local("OswaldMedium"),
  url("./assets/Oswald-Medium.ttf") format("truetype");
}

@font-face {
 font-family: "OswaldRegular";
 src: local("OswaldRegular"),
  url("./assets/Oswald-Regular.ttf") format("truetype");
}

@font-face {
 font-family: "OswaldSemiBold";
 src: local("OswaldSemiBold"),
  url("./assets/Oswald-SemiBold.ttf") format("truetype");
}
